import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import ActivityPageDesktop from "nlib/pages/ActivityPage";
import React from "react";
import classNames from "classnames";

const ActivityPage = () => {
  return (
    <ActivityPageDesktop className={classNames(Css.activityPage, CommonCss.mobileContainer)} />
  );
};

export default React.memo(ActivityPage);
